import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios";
import Spinner from "../../../Components/Spinner";
import CustomSliceTooltip from "../../../Components/Tooltip";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { toast } from "react-toastify";

const timestamps = [
  { label: "00:00", value: 0 },
  { label: "00:15", value: 1 },
  { label: "00:30", value: 2 },
  { label: "00:45", value: 3 },
  { label: "01:00", value: 4 },
  { label: "01:15", value: 5 },
  { label: "01:30", value: 6 },
  { label: "01:45", value: 7 },
  { label: "02:00", value: 8 },
  { label: "02:15", value: 9 },
  { label: "02:30", value: 10 },
  { label: "02:45", value: 11 },
  { label: "03:00", value: 12 },
  { label: "03:15", value: 13 },
  { label: "03:30", value: 14 },
  { label: "03:45", value: 15 },
  { label: "04:00", value: 16 },
  { label: "04:15", value: 17 },
  { label: "04:30", value: 18 },
  { label: "04:45", value: 19 },
  { label: "05:00", value: 20 },
  { label: "05:15", value: 21 },
  { label: "05:30", value: 22 },
  { label: "05:45", value: 23 },
  { label: "06:00", value: 24 },
  { label: "06:15", value: 25 },
  { label: "06:30", value: 26 },
  { label: "06:45", value: 27 },
  { label: "07:00", value: 28 },
  { label: "07:15", value: 29 },
  { label: "07:30", value: 30 },
  { label: "07:45", value: 31 },
  { label: "08:00", value: 32 },
  { label: "08:15", value: 33 },
  { label: "08:30", value: 34 },
  { label: "08:45", value: 35 },
  { label: "09:00", value: 36 },
  { label: "09:15", value: 37 },
  { label: "09:30", value: 38 },
  { label: "09:45", value: 39 },
  { label: "10:00", value: 40 },
  { label: "10:15", value: 41 },
  { label: "10:30", value: 42 },
  { label: "10:45", value: 43 },
  { label: "11:00", value: 44 },
  { label: "11:15", value: 45 },
  { label: "11:30", value: 46 },
  { label: "11:45", value: 47 },
  { label: "12:00", value: 48 },
  { label: "12:15", value: 49 },
  { label: "12:30", value: 50 },
  { label: "12:45", value: 51 },
  { label: "13:00", value: 52 },
  { label: "13:15", value: 53 },
  { label: "13:30", value: 54 },
  { label: "13:45", value: 55 },
  { label: "14:00", value: 56 },
  { label: "14:15", value: 57 },
  { label: "14:30", value: 58 },
  { label: "14:45", value: 59 },
  { label: "15:00", value: 60 },
  { label: "15:15", value: 61 },
  { label: "15:30", value: 62 },
  { label: "15:45", value: 63 },
  { label: "16:00", value: 64 },
  { label: "16:15", value: 65 },
  { label: "16:30", value: 66 },
  { label: "16:45", value: 67 },
  { label: "17:00", value: 68 },
  { label: "17:15", value: 69 },
  { label: "17:30", value: 70 },
  { label: "17:45", value: 71 },
  { label: "18:00", value: 72 },
  { label: "18:15", value: 73 },
  { label: "18:30", value: 74 },
  { label: "18:45", value: 75 },
  { label: "19:00", value: 76 },
  { label: "19:15", value: 77 },
  { label: "19:30", value: 78 },
  { label: "19:45", value: 79 },
  { label: "20:00", value: 80 },
  { label: "20:15", value: 81 },
  { label: "20:30", value: 82 },
  { label: "20:45", value: 83 },
  { label: "21:00", value: 84 },
  { label: "21:15", value: 85 },
  { label: "21:30", value: 86 },
  { label: "21:45", value: 87 },
  { label: "22:00", value: 88 },
  { label: "22:15", value: 89 },
  { label: "22:30", value: 90 },
  { label: "22:45", value: 91 },
  { label: "23:00", value: 92 },
  { label: "23:15", value: 93 },
  { label: "23:30", value: 94 },
  { label: "23:45", value: 95 },
];

const LoadCh = () => {
  const [chartData, setChartData] = useState(null);
  const [xcord, setXCord] = useState()
  const [ycord, setYCord] = useState()
  const [hoveredId, setHoveredId] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const fetchChartData = async() => {
  //   try {
  //     const response = localStorage.getItem('selectedFile');
  //     console.log("response",response)
  //     const data = JSON.parse(response);
  //     console.log("data", response)
  //     console.log("Parsed data:", data);

  //     if (!data || !data.data || !data.data.length) {
  //       console.error("Empty or undefined data received for predictLoad.");
  //       return;
  //     }
  //   const  chartData = data.data.map((item,index) =>({
  //     x: timestamps[index].label,
  //     temp: item.blocks__temp,
  //     rh: item.blocks__rh,
  //     wdLoad: item.blocks__wdLoad,
  //   }));
  //   console.log("chartData :",chartData)
  //   setChartData(chartData);
  //   } catch (error) {
  //     console.error("error")
  //   }
  // }
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = localStorage.getItem("selectedFile");
        if (!response) {
          
          return;
        }
        let data = JSON.parse(response);
        if (data.length > 97) {
          toast.error('Data exceeds the expected 96 blocks. Please check the file.');
          return;
        }

        if (!data || !data.length) {
          
          return;
        }
        if (!Array.isArray(data) || data.length === 0) {
          toast.error("Empty or invalid data received.");
          return;
        }

        const formattedData = data.slice(1).map((item, index) => ({
          x: timestamps[index]?.label,  // Using timestamps for x-axis
          wdLoad:item.wdLoad          // Data for y-axis
        }));

        setChartData(formattedData);
      } catch (error) {
        
      }
    };

    fetchChartData();
  }, []);
  if (!chartData) {
    return <Spinner />;
  }



  const handleMouseEnter = (point) => {
    setHoveredId(point.points[0].data.x);
    let ymax = 0
    point.points.forEach(point => {
      const ynow = point.y;
      if (ynow > ymax) {
        ymax = ynow;
      }
    });
    setXCord(point.points[0].x0)
    setYCord(point.height - ymax);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  return (
    <ResponsiveLine
      data={[
        {
          id: "Load",
          data: chartData.map((item) => ({ x: item.x, y: item.wdLoad })),
        },
        // {
        //   id: "Temperature",
        //   data: chartData.map((item) => ({ x: item.x, y: item.temp })),
        // },
        // {
        //   id: "Relative Humidity",
        //   data: chartData.map((item) => ({ x: item.x, y: item.rh })),
        // },
      ]}
      margin={{ top: 10, right: 20, bottom: 60, left: 50 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      enableCrosshair={false}

        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
      axisTop={null}
      axisRight={null}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.primary['black']
            },
          },
          ticks: {
            text: {
              fill: colors.primary['black']
            },
            line: {
              stroke: colors.grey[100],
            },
          },
        },
        grid: {
          line: {
            stroke: colors.grey[480], // Change this color to customize the grid lines color
            strokeWidth: 1,
          }
        }

      }}
      enableSlices="x"
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: colors.primary["light"],
              padding: '0.563rem 0.75rem',
              // border: '1px solid #ccc',
              borderRadius: "16px"
            }}
          >
            <div
              style={{
                fontSize: '1rem',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: "600",
                color: colors.primary["dark"]
              }}
            >
              Timestamp: {slice.points[0].data.x}
            </div>
            {slice.points.map((point) => (
              <div key={point.id}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: point.serieColor,
                      marginRight: 8,
                    }}
                  ></div>
                  <div
                    style={{
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: '100'
                    }}
                  >
                    {point.serieId}: {Number(point.data.yFormatted).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Time",
        legendOffset: 25,
        legendPosition: "middle",
        tickValues: timestamps
          .filter(
            (timestamp) =>
              timestamp.label.endsWith(":00") && timestamp.value % 3 === 0
          )
          .map((timestamp) => timestamp.label),
        format: (value) => value.slice(0, 2),
      }}
      axisLeft={{
        orient: "left",
        tickSize: 0,
        tickPadding: 7,
        tickRotation: 0,
        legend: "Load",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      colors={["#2C2C2C"]}
      pointSize={4}
      pointColor="#ffffff"
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      enableGridX={false}
      enableArea={false}
      useMesh={true}
      linewidth={3}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          toggleSerie: true,
          justify: false,
          translateX: 10,
          translateY: 55,
          itemsSpacing: 50,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: colors.primary['black'],

          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      pointSymbol={(point) => {
        const lineColor = point.borderColor
       
        const isHovered = point.datum.x === hoveredId && hoveredId !== null;
        
        return (
          <g>
            {isHovered && (
              <line
                x1={xcord}
                y1="-100%"
                x2={xcord}
                y2={ycord}
                style={{ stroke: '#FFA07A', strokeWidth: 1, pointerEvents: 'none' }}
              />
            )}
            <circle
              cx={0}
              cy={0}
              r={isHovered ? 6 : 2}
              fill={lineColor}
              fillOpacity={0.8}
              strokeWidth={isHovered ? 0.6 : 0.2}
              stroke={isHovered ? { lineColor } : '#FFFFFF'}
            />
          </g>
        );
      }}

    />
  );
};

export default LoadCh;